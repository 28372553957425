import { RECORDING_DEFAULT_VALUES, SONG_DEFAULT_VALUES } from "components/const"

const formatSongs = (data) => {
  let songs = []

  for (const song of data) {
    songs.push(
      {
        id: song?.id,
        controlled: song?.controlled,
        title: song?.title,
        exp_composition_code: song?.exp_composition_code,
        custom_id: song?.custom_id,
        controlled_writer_list: song?.controlled_writer_list,
        uncontrolled_publishers: song?.uncontrolled_publishers,
        uncontrolled_info: song?.uncontrolled_info,
        credits: song?.credits,
        status: song?.status
      }
    )
  }

  if (songs.length === 0) songs.push(SONG_DEFAULT_VALUES)

  return songs
}

const formatRecordings = (data) => {
  let recordings = []

  for (const recording of data) {
    recordings.push(
      {
        id: recording?.id,
        title: recording?.title,
        exp_recording_code: recording?.exp_recording_code,
        controlled_artists: recording?.controlled_artists,
        uncontrolled_artists: recording?.uncontrolled_artists,
        client_name: recording?.client_name,
        client_code: recording?.client_code,
        controlled_percentage: recording?.controlled_percentage,
        territory: recording?.territory,
        credits: recording?.credits,
        status: recording?.status
      }
    )
  }

  if (recordings.length === 0) recordings.push(RECORDING_DEFAULT_VALUES)

  return recordings
}

export const formatFields = (data) => {
  return {
    id: data.id,
    license_id: data.license_id,
    right_type: data.right_type,
    songs: formatSongs(data.songs),
    recordings: formatRecordings(data.recordings),
    indefinite_term_length: data.indefinite_term_length,
    licensed_territory: data.licensed_territory,
    fee: data.fee,
    payment_terms: data.payment_terms,
    notes: {
      id: data.notes?.id,
      content: data.notes?.content
    },
    project: {
      id: data.project.id,
      title: data.project.title,
      clearance_quote: data.project.clearance_quote,
      category: data.project.category,
      fee: data.project.fee,
      media: data.project.media,
      territory: data.project.territory,
      air_date: data.project.air_date,
      request: data.project.request,
      scene_description: data.project.scene_description,
      approval_needed: data.project.approval_needed,
      contact_name: data.project.contact_name,
      contact_email: data.project.contact_email,
      contact_company: data.project.contact_company,
      contact_title: data.project.contact_title,
      contact_phone_number: data.project.contact_phone_number,
      contact_location: data.project.contact_location,
    },
    licensee: {
      id: data.licensee.id,
      name: data.licensee.name,
      email: data.licensee.email,
      phone_number: data.licensee.phone_number,
      license_signatory: data.licensee.license_signatory
    },
    company: {
      id: data.licensee.company.id,
      name: data.licensee.company.name,
      address: data.licensee.company.address,
      city: data.licensee.company.city,
      state: data.licensee.company.state,
      postal_code: data.licensee.company.postal_code,
      phone_number: data.licensee.company.phone_number,
      country: data.licensee.company.country
    },
    license_date: data.license_date,
    term_length: data.term_length,
    attachments: data.attachments
  }
}
